<template>
	<div class="microMall">
		<applyFrom @close="close" v-if="applyFromshow"></applyFrom>
		<customerService @close-popup="closePopup" v-if="customerService"></customerService>
		<div class="banner">
			<div class="banner-body flex">
				<div class="banner-body-title flex">
					<div style="width: 55%;align-items: initial;flex-direction: column;line-height: 0.50rem;"
						class="flex">
						<span style="font-size: 0.30rem; color: #FFFFFF;font-family: Source Han Sans CN;">力瀚微商城系统</span>
						<span style="font-size: 0.17rem;color: #fff;font-family: Source Han Sans CN;">借助拥有亿万用户的市场发力
							全面玩转微信营销 把商城开到微信里</span>
						<div class="sqsy" @click="customerService = true">咨询购买</div>
					</div>
				</div>
				<div class="banner-body-img flex">
					<img class="banner-body-img-img0"
						style="position: absolute;left: 0;width: 2.07rem;z-index: 11;top: 0.30rem;"
						src="../assets/img/microMall-banner1.png">
					<img class="banner-body-img-img1"
						style="position: absolute;left: 1.25rem;width: 1.74rem;top: 0.70rem;"
						src="../assets/img/microMall-banner2.png">
				</div>
			</div>
			<img style="width: 100%;height: 100%;" src="../assets/img/Journalismbanner.png">
		</div>

		<div class="transformation">
			<div class="worry-tit">
				<span class="worry-tit-t">结合微信优势 轻松开店赚钱</span><br />
				<span class="worry-tit-b">力瀚通过微信自带流量，然后在技术领域加以开发，与微信相互结合，产生众多的特色优势， 让您的商城在微信这个移动市场更加轻松快速的赚钱</span>
			</div>
			<div class="transformation-box flex">
				<div class="transformation-box-cell flex" v-for="(item,index) in iconlist" :key="index">
					<img :style="{'width': item.with }" :src="item.img">
					<div style="flex: 1;text-align: left;margin-left: 0.20rem;">
						<div style="font-size: 0.17rem;color: #333;">{{item.title}}</div>
						<div style="font-size: 0.14rem;color: #8F8F8F;margin-top: 0.10rem;">{{item.lable}}</div>
					</div>
				</div>
			</div>
		</div>

		<div class="Exhibition flex">
			<div class="Exhibition-box flex">
				<div class="Exhibition-box-L flex">
					<img src="../assets/img/microMall-l.png"
						style="margin-right: 0.40rem;position: relative;z-index: 103;width: 0.28rem;" @click="toleft">
						<img v-if="ExhibitionIndex == 0" style="max-width: 3.11rem;" src="../assets/img/microMall-microMallSy.png">
					<img style="max-width: 3.11rem;" v-else
						:src="require('../assets/img/microMall-microMall'+ (ExhibitionIndex) +'.png')">
					<img src="../assets/img/microMall-r.png"
						style="margin-left: 0.40rem;position: relative;z-index: 103;width: 0.28rem;" @click="toright">
				</div>
				<div class="Exhibition-box-L-L">
					<div class="Exhibition-box-r-tit">力瀚微商城界面展示</div>
					<div class="Exhibition-box-r-cell flex">
						<!-- <div class="Exhibition-box-r-cell-c flex" v-for="(item,index) in ExhibitionList" :key="index"
							:class="index == 0 ? 'iconcol' : ''">
							<img style="width: 27px;" :src="item.img">
							<div class="r-box-r-cell-span">{{item.title}}</div>
						</div> -->
						<div class="Exhibition-box-r-cell-c" v-for="(item,index) in ExhibitionList" :key="index">
							<img :style="{'width': ExhibitionIndex == item.LimgIndex ? item.withy : item.with}"
								:src="ExhibitionIndex == item.LimgIndex ? item.imgyl : item.img"
								@click="setIndex(index)">
						</div>

					</div>

					<div class="QRcode">
						<img style="width: 1.60rem;border-radius: 5px;" src="../assets/img/microMall-qrcode.png">
						<div class="QRcode-text">扫码查看演示</div>
					</div>
				</div>
			</div>
		</div>

		<div class="edit">
			<div class="worry-tit">
				<span class="worry-tit-t">首页可视化编辑</span><br />
				<span class="worry-tit-b">帮助商家更好的装修首页，在后台轻松编辑即可完成，实时保存预览效果</span>
			</div>
			<div class="edit-imgbox">
				<img style="width: 100%;" src="../assets/img/microMall-edit.png">
			</div>
		</div>

		<div class="conversion">
			<div class="conversion-box flex">
				<div style="flex: 1;">
					<div
						style="width: 100%;text-align: left;color: #333333;font-size: 0.27rem;font-family: Source Han Sans CN;line-height: 0.40rem;">
						<span>多种促销工具</span><br />
						<span>帮助提升店铺成交转化</span>
					</div>
					<div
						style="color: #666666;font-size: 0.12rem;margin-top: 0.40rem;text-align:left;padding-right: 1.00rem;">
						客户营销不能只是单单的打折促销，需要配合多种促销玩法， 把消费变成一种“游戏”变得更加有趣，反而更有效果</div>
				</div>
				<img style="max-width: 4.05rem;" src="../assets/img/microMall-conversion.png">
			</div>
		</div>

		<div class="edit">
			<div class="worry-tit">
				<span class="worry-tit-t">多种营销互动玩法</span><br />
				<span class="worry-tit-b">力瀚融合微信市场特性，为用户研发出众多针对性功能，管理用户，互动营销等特色功能，让你更好的做微商城</span>
			</div>
			<div class="edit-imgbox" style="width: 6.73rem;">
				<img style="width: 100%;" src="../assets/img/microMall-take.png">
			</div>
		</div>

		<div class="sales">
			<div class="worry-tit">
				<span class="worry-tit-t">结合分销和拼团 引爆商品销量</span><br />
			</div>
			<div class="sales-box flex">
				<div class="sales-box-cell">
					<div style="font-size: 0.11rem;font-weight: bold;color: #333333;">拼团</div>
					<div style="font-size: 0.09rem;color: #8F8F8F;margin-top: 0.10rem;">基于微信开展拼团，依靠 朋友圈的飞速传播，提升品牌
						影响力，促进消费者分享带来 商品销量</div>
				</div>
				<div style="margin: 0 0.20rem;width: 9.4rem;">
					<img style="width: 100%;" src="../assets/img/microMall-sales.png">
				</div>
				<div class="sales-box-cell">
					<div style="font-size: 0.11rem;font-weight: bold;color: #333333;">微分销</div>
					<div style="font-size: 0.09rem;color: #8F8F8F;margin-top: 0.10rem;">通过力瀚微分销建立一支分 销军团，让你的员工，客户
						以及上亿微信用户帮你卖货</div>
				</div>
			</div>
		</div>

		<div class="shop">
			<div class="conversion-box flex">
				<div style="flex: 1;">
					<div
						style="width: 100%;text-align: left;color: #333333;font-size: 0.27rem;font-family: Source Han Sans CN;line-height: 0.40rem;">
						<span>入驻商的店铺</span><br />
						<span>可对接自己的公众号</span>
					</div>
					<div
						style="color: #666666;font-size: 0.12rem;margin-top: 0.40rem;text-align:left;padding-right: 1.00rem;">
						通过力瀚微商城系统，可以让每个商家的店铺 都能对接自己的公众号，对接后商家公众号 的粉丝也会被平台吸纳，互惠互利
					</div>
				</div>
				<div>
					<img style="width: 4.05rem;" src="../assets/img/microMall-shop.png">
				</div>

			</div>
		</div>

		<div class="Collage">
			<div class="conversion-box flex">
				<div class="Collage-L flex">
					<img style="position: absolute;left: 0;width: 2.07rem;z-index: 11;top: 0.30rem;"
						src="../assets/img/microMall-banner1.png">
					<img style="position: absolute;left: 1.25rem;width: 1.74rem;top: 0.70rem;"
						src="../assets/img/microMall-banner2.png">
				</div>

				<div class="Collage-R">
					<div style="color: #333333;font-size: 0.27rem;">
						<span>搭配力瀚微分销与拼团</span><br />
						<span>打造微信一站式营销方案</span>
					</div>
					<div class="Collage-R-lab">
						力瀚微商城可结合微分销，建立一支分销军团，
						让你的员工，客户，以及上亿微信用户帮你卖货，
						再通过力瀚拼团，依靠朋友圈的飞速传播，
						迅速提升品牌影响力，促进消费者分享
					</div>

					<div class="Collage-R-text">
						<el-button type="text" @click="customerService = true">了解拼团<i
								class="el-icon-arrow-right el-icon--right"></i></el-button>
						<el-button style="margin-left: 0.40rem;" type="text" @click="customerService = true">了解分销<i
								class="el-icon-arrow-right el-icon--right"></i></el-button>
					</div>
					<el-button @click="customerService = true"
						style="background: linear-gradient(90deg, #2E89F9, #06D8EE);width: 2.20rem;" type="primary">查看套餐
					</el-button>

				</div>
			</div>
		</div>

		<div class="programme">
			<div class="programme-tit">支持私有化部署，满足定制开发需求</div>
			<div class="programme-but flex">
				<div class="programme-but-input">打造专属微商城，力瀚更懂你的需求</div>
				<div class="programme-but-but flex" @click="applyFromshow = true">点击申请</div>
			</div>
		</div>
	</div>
</template>

<script>
	import applyFrom from '@/components/applyFrom.vue';
	import customerService from '@/components/customerService.vue';
	export default {
		data() {
			return {
				applyFromshow: false,
				customerService: false,
				ExhibitionIndex: 1,
				LimgKey: 0,
				ExhibitionList: [{
						img: require('../assets/img/microMall-microMall-icon1.png'),
						imgyl: require('../assets/img/microMall-microMall-icon1yl.png'),
						title: '首页',
						LimgIndex: 1,
						withy: '1.26rem',
						with: '0.54rem'
					},
					{
						img: require('../assets/img/microMall-microMall-icon2.png'),
						imgyl: require('../assets/img/microMall-microMall-icon2yl.png'),
						title: '商品详情',
						LimgIndex: 2,
						withy: '1.28rem',
						with: '0.81rem'
					},
					// {
					// 	img: require('../assets/img/microMall-microMall-icon3.png'),
					// 	imgyl: require('../assets/img/microMall-microMall-icon3yl.png'),
					// 	title: '直播',
					// 	withy: '1.28rem',
					// 	with: '0.62rem'
					// },
					// {
					// 	img: require('../assets/img/microMall-microMall-icon4.png'),
					// 	imgyl: require('../assets/img/microMall-microMall-icon4yl.png'),
					// 	title: '短视频',
					// 	withy: '1.28rem',
					// 	with: '0.61rem'
					// },
					// {
					// 	img: require('../assets/img/microMall-microMall-icon5.png'),
					// 	imgyl: require('../assets/img/microMall-microMall-icon5yl.png'),
					// 	title: '社区',
					// 	withy: '1.28rem',
					// 	with: '0.52rem'
					// },
					{
						img: require('../assets/img/microMall-microMall-icon6.png'),
						imgyl: require('../assets/img/microMall-microMall-icon6yl.png'),
						title: '店铺街',
						LimgIndex: 6,
						withy: '1.28rem',
						with: '0.62rem'
					},
					{
						img: require('../assets/img/microMall-microMall-icon7.png'),
						imgyl: require('../assets/img/microMall-microMall-icon7yl.png'),
						title: '会员中心',
						LimgIndex: 7,
						withy: '1.28rem',
						with: '0.81rem'
					},
					// {
					// 	img: require('../assets/img/microMall-microMall-icon8.png'),
					// 	imgyl: require('../assets/img/microMall-microMall-icon8yl.png'),
					// 	title: '资金管理',
					// 	withy: '1.28rem',
					// 	with: '0.81rem'
					// }
				],
				iconlist: [{
						img: require('../assets/img/microMall-icon1.png'),
						title: '跨越移动和PC',
						lable: '跨越传统互联网，全面进入微信端， 抢占市场先机，打造双平台盈利',
						with: '0.51rem'
					},
					{
						img: require('../assets/img/microMall-icon2.png'),
						title: '便捷下单付款',
						lable: '随时随地微信下单、支付，消费更 便捷、更时尚，订单更快到账',
						with: '0.44rem'
					},
					{
						img: require('../assets/img/microMall-icon3.png'),
						title: '海量用户 随时购物',
						lable: '微信超10亿活跃用户，海量潜在消费 客户，打破时间、空间限制',
						with: '0.63rem'
					},
					{
						img: require('../assets/img/microMall-icon4.png'),
						title: '互动娱乐 消费者依赖',
						lable: '力瀚公众平台通过每日签到送积分等活动，让消费者对您更信赖更依赖',
						with: '0.46rem'
					},
					{
						img: require('../assets/img/microMall-icon5.png'),
						title: '实时消息 精准推送',
						lable: '力瀚微店让您掌握更精准的客户信息，并可推送个性化信息给指定用户',
						with: '0.48rem'
					},
					{
						img: require('../assets/img/microMall-icon6.png'),
						title: '统一管理 去繁从简',
						lable: '免去繁琐推广，操作便捷，力瀚后台 统一管理，快速设置和传达信息',
						with: '0.44rem'
					}
				]
			}
		},
		components: {
			// Canvas，
			applyFrom,
			customerService
		},
		methods: {
			close() {
				this.applyFromshow = false;
			},

			closePopup() {
				this.customerService = false
			},
			
			setIndex(index){
				this.LimgKey = index;
				this.ExhibitionIndex = this.ExhibitionList[index].LimgIndex
			},
			
			toleft() {
				if (this.LimgKey > 0) {
					this.LimgKey--
					
				} else {
					this.LimgKey = 0
				}
				
				this.ExhibitionIndex = this.ExhibitionList[this.LimgKey].LimgIndex
			},
			toright() {
				if (this.LimgKey == 3) {
					console.log('++')
					this.LimgKey = 0
				} else {
					this.LimgKey++
				}
				this.ExhibitionIndex = this.ExhibitionList[this.LimgKey].LimgIndex
			}
		}
	}
</script>

<style lang="scss" scoped>
	@media screen and (max-width: 750px) {
		.introduce-box {
			width: 80% !important;
		}

		.conversion-box {
			width: 90% !important;
		}

		.Exhibition-box-r-cell-c {
			width: 40% !important;
			margin-right: 6% !important;
		}

		.Exhibition-box {
			width: 95% !important;
		}

		.Exhibition-box-L-L {
			margin-left: 0 !important;
		}

		.Marketing-imgbox-img1 {
			width: 2.5rem !important;
		}

		.Exhibition-box-L-img {
			width: 2.5rem !important;
		}

		.banner {
			height: 4rem !important;
		}

		.banner-body {
			width: 95% !important;
			margin: 0 auto;
		}

		.banner-body-title {
			width: 50% !important;

			.banner-body-title-span {
				width: 100% !important;
			}
		}

		.banner-body-img {
			width: 50% !important;

			.banner-body-img-img0 {
				top: 1.3rem !important;
			}

			.banner-body-img-img1 {
				top: 1.8rem !important;
			}
		}
	}

	.banner {
		width: 100%;
		height: 3.00rem;
		// padding-top: 30px;
		position: relative;
		z-index: 101;

		.banner-body {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			right: 0;

			// padding: 40px 0;
			.banner-body-img {
				width: 35%;
				height: 100%;
				text-align: left;
				justify-content: initial;
				position: relative;
			}

			.banner-body-title {
				width: 44%;
				height: 100%;
				// flex-direction: column;
				text-align: left;
				// justify-content: right;

				// align-items: initial;
				.sqsy {
					width: 1.80rem;
					height: 0.5rem;
					border-radius: 6px;
					// margin: 0 auto;
					margin-top: 0.20rem;
					background-image: url(../assets/img/sqsy.png);
					background-repeat: no-repeat;
					font-size: 0.25rem;
					text-align: center;
					line-height: 0.50rem;
					font-size: 0.24rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
				}
			}
		}

		// background-image: url(../assets/img/Journalismbanner.png);
	}

	.transformation {
		width: 100%;
		padding: 0.80rem 0;

		.worry-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.worry-tit-t {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333;
			}

			.worry-tit-b {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666;
			}
		}

		.transformation-box {
			// width: 60%;
			margin: 0 auto;
			margin-top: 0.80rem;
			// justify-content: space-between;
			flex-wrap: wrap;

			.transformation-box-cell {
				width: 31%;
				align-items: start;
				margin-bottom: 0.60rem;
			}
		}
	}

	.iconcol {
		background-color: #fff;
		border-radius: 4px;

		.r-box-r-cell-span {
			color: #2E89F9 !important;
		}
	}

	.Exhibition {
		width: 100%;
		// height: 480px;
		padding: 0.60rem 0;
		background: linear-gradient(90deg, #2E89F9, #73B2FF);

		.Exhibition-box {
			// width: 50%;
			height: 100%;
			align-items: unset;

			.Exhibition-box-L-img {
				width: 3.11rem;
			}

			.Exhibition-box-L-L {
				flex: 1;
				height: 80%;
				width: 5.5rem;
				margin-left: 1.50rem;

			}

			.Exhibition-box-r-tit {
				text-align: left;
				font-size: 0.36rem;
				font-family: Source Han Sans CN;
				font-weight: 500;
				color: #fff;
			}

			.QRcode {
				width: 100%;
				text-align: left;
				margin-top: 0.20rem;

				.QRcode-text {
					width: 1.60rem;
					padding: 0.10rem 0;
					font-size: 0.15rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
					text-align: center;
				}
			}

			.Exhibition-box-r-cell {
				// width: 5.00rem;
				flex-wrap: wrap;
				margin-top: 0.70rem;
				// justify-content: space-between;

				.r-box-r-cell-span {
					font-size: 0.12rem;
					color: #FFFFFF;
					margin-top: 0.10rem;
				}

				.Exhibition-box-r-cell-c {
					width: 24%;
					height: 2.00rem;
					margin-right: 1%;
					// margin-bottom: 70px;
					// flex-direction: column;
					position: relative;
					z-index: 103;
				}
			}
		}
	}

	.edit {
		width: 100%;
		padding: 0.80rem 0;

		.worry-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.worry-tit-t {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333;
			}

			.worry-tit-b {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666;
			}
		}

		.edit-imgbox {
			width: 13.08rem;
			margin: 0 auto;
			margin-top: 0.80rem;
		}

	}

	.conversion {
		width: 100%;
		padding: 0.60rem 0;
		background-color: #f7f7f7;

		.conversion-box {
			width: 50%;
			margin: 0 auto;
			align-items: initial;
		}
	}

	.sales {
		width: 100%;
		padding: 0.60rem 0;
		background-color: #f7f7f7;

		.worry-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.worry-tit-t {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333;
			}
		}

		.sales-box {
			// width: 50%;
			margin: 0 auto;
			margin-top: 0.60rem;

			.sales-box-cell {
				width: 1.60rem;
				text-align: left;
				padding-right: 0.20rem;
			}
		}
	}

	.shop {
		width: 100%;
		padding: 0.60rem 0;

		.conversion-box {
			width: 50%;
			margin: 0 auto;
			align-items: initial;
		}
	}

	.Collage {
		width: 100%;
		padding: 0.60rem 0;
		background-color: #f7f7f7;

		.conversion-box {
			width: 50%;
			margin: 0 auto;
			align-items: initial;

			.Collage-L {
				width: 50%;
				position: relative;
			}

			.Collage-R {
				flex: 1;
				text-align: left;

				.Collage-R-lab {
					width: 2.60rem;
					margin-top: 0.30rem;
					font-size: 0.12rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #666666;
				}

				.Collage-R-text {
					margin: 0.30rem 0;
				}
			}
		}
	}

	.programme {
		width: 100%;
		height: 2.00rem;
		background-image: url(../assets/img/dg-bg.png);

		.programme-tit {
			width: 100%;
			text-align: center;
			font-size: 0.27rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #FFFFFF;
			padding: 0.40rem 0;
		}

		.programme-but {
			// width: 40%;
			margin: 0 auto;

			.programme-but-input {
				width: 3.20rem;
				height: 0.45rem;
				border: 1px solid #ccc;
				font-size: 0.15rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 0.45rem;
			}

			.programme-but-but {
				width: 1.50rem;
				height: 0.45rem;
				font-size: 0.20rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 0.60rem;
				border-radius: 5px;
				background: linear-gradient(90deg, #2E89F9, #06D8EE);
			}
		}
	}
</style>
